// 倒计时
/*
date：传入时间--时间戳格式
callback：回调函数
setTime：定时器执行时间--默认1000(一秒)
*/
var timer = null;
export function getTimer(date, callBack, setTime = 1000, stopTime) {
  clearTimeout(timer);
  if (stopTime) {
    return;
  }
  // var leftTime = new Date(date).getTime() - new Date().getTime(); //计算剩余的毫秒数
  var leftTime = date - new Date().getTime(); //计算剩余的毫秒数
  if (leftTime <= 0) leftTime = 0;
  var days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余的天数
  var hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10); //计算剩余的小时
  var minutes = parseInt((leftTime / 1000 / 60) % 60, 10); //计算剩余的分钟
  var seconds = parseInt((leftTime / 1000) % 60, 10); //计算剩余的秒数
  days = paddingZero(days);
  hours = paddingZero(hours);
  minutes = paddingZero(minutes);
  seconds = paddingZero(seconds);
  if (leftTime >= 0) {
    timer = setTimeout(() => {
      getTimer(date, callBack, (setTime = 1000));
    }, setTime);
  }
  let returnData = {
    days,
    hours,
    minutes,
    seconds,
  };
  callBack(returnData);
  function paddingZero(i) {
    //将0-9的数字前面加上0，例1变为01
    return i < 10 ? "0" + i : i;
  }
}
export function getFileType(name) {
  if (!name) return false;
  var imgType = ["gif", "jpeg", "jpg", "bmp", "png"];
  var videoType = [
    "avi",
    "wmv",
    "mkv",
    "mp4",
    "mov",
    "rm",
    "3gp",
    "flv",
    "mpg",
    "rmvb",
  ];
  if (RegExp(".(" + imgType.join("|") + ")$", "i").test(name.toLowerCase())) {
    return "image";
  } else if (
    RegExp(".(" + videoType.join("|") + ")$", "i").test(name.toLowerCase())
  ) {
    return "video";
  } else {
    return false;
  }
}
// 计算时间差
export function getDaysBetween(dateString2) {
  var date = new Date();
  var nowMonth = date.getMonth() + 1;
  var strDate = date.getDate();
  var seperator = "-";
  var d1 = date.getFullYear() + seperator + nowMonth + seperator + strDate;
  var startDate = Date.parse(d1);
  var endDate = Date.parse(dateString2);
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  if (startDate > endDate) {
    return "逾期了";
  }
  if (days < 30) {
    return days;
  }
  return days;
}

// 图片检测格式和大小
export function validateImgUpload(file, type, size, unitName = "KB") {
  let isRightType;
  let isLt2M;
  var res = { type: "error", message: "" };
  if (Array.isArray(type)) {
    isRightType = type.indexOf(file.type) !== -1;
  } else {
    isRightType = type === file.type;
  }
  if (unitName === "KB") {
    isLt2M = file.size / 1024 < size;
  } else if (unitName === "MB") {
    isLt2M = file.size / 1024 / 1024 < size;
  }
  if (!isRightType) {
    res.message = "上传资源格式不符!";
    return res;
  }
  if (!isLt2M) {
    res.message = "上传资源大小不能超过" + size + unitName + "!";
    return res;
  }
  res.type = "success";
  return res;
}
// export default { getTimer };

/**
 * 防抖
 */
export function debounce(callback, time) {
  let timer = null;
  return function () {
    if (timer !== null) clearTimeout(timer);
    timer = setTimeout(() => {
      callback();
    }, time);
  };
}
