<template>
  <div id="micro-ai">
    <sidebar class="sidebar-container" :class="'sidebar-container-dark'" v-if="!getQiankun && showSidebar" />
    <div class="main-container app-main" :class="{ 'main-container-copy': getQiankun || !showSidebar }">
      <!-- <Navbar v-if="!getQiankun"></Navbar> -->
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade-transform" mode="out-in">
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import { setToken, getToken } from '@/utils/auth'

export default {
  name: 'App',
  data() {
    return {
      getQiankun: window.__POWERED_BY_QIANKUN__
    }
  },
  computed: {
    showSidebar() {
      return this.$store.state.user.showSidebar;
    }
  },
  components: {
    Sidebar,
    Navbar
  },
  created() {
    if (this.$route.query.token) {
      setToken(this.$route.query.token)
    }
  },
}
</script>

<style lang="scss">
@import "src/styles/mixin.scss";

#micro-ai {
  width: 100%;
  height: 100%;
}

.app-main {
  /*84 = navbar + tags-view = 50 +34 */
  background: #F0F2F5;
  min-height: calc(100vh - 84px);
  position: relative;
  overflow: visible;
}

.handlerTab_tooltip {
  max-width: 200px;
}
.main-container-copy{
  margin-left: 0!important;
}
</style>
