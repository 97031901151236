<template xmlns:el-icon-close="http://www.w3.org/1999/html">
  <div>
    <el-row type="flex" class="navbar" mode="horizontal">
      <!-- 按钮条 -->
      <div class="right-menu">
        asd
        <el-divider direction="vertical" />
      </div>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped></style>
