import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { getToken, getCurrentRoleCode } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // debugger
    // Do something before request is sent
    if (getToken()) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      config.headers['Mayi-Token'] = getToken()
      var href = window.location.href
      href = href.substring(href.indexOf('#') + 1, href.length)
      // config.headers['permission-url'] = href
      /* config.headers['dubbo-tag'] = 'lisongtao' */
    }
    // 如果有中文encodeURIComponent一下
    config.headers['Current-Role-Code'] = encodeURIComponent(getCurrentRoleCode())
    if (process.env.NODE_ENV === 'development') {
      // console.log("axios config", config)
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone interceptor
service.interceptors.response.use(
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const res = response.data
    res.ok = !(res.ok === 'false' || !res.ok)
    // if (!getToken()) {
    //   this.$alert('你已被退出，请重新登录', '', {
    //     confirmButtonText: '确定',
    //     callback: action => {
    //       // location.reload() // 为了重新实例化vue-router对象 避免bug
    //     }
    //   })
    //   return
    // }
    if (res.code != '200') {
      // 未登录res.code === '-1' ||
      if (res.code == 401) {
        var msg = '你已被退出，请重新登录'
        if (res.msg) {
          msg = res.msg
        }
        // 请自行在引入 MessageBox
        // import { Message, MessageBox } from 'element-ui'
        // MessageBox.confirm(msg, '登录失败', {
        //   confirmButtonText: '重新登录',
        //   type: 'warning'
        // }).then(() => {
        console.log()
        if (response.request.responseURL.includes('/employeeInfo/user') && (res.data && res.data.errorType == 0)) {
          const msg = `此账号已存在，但尚未分配角色，请联系管理员`
          MessageBox(msg, '提示', {
            confirmButtonText: '确定'
          }).then(() => {
            // location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        } else {
          // location.reload() // 为了重新实例化vue-router对象 避免bug
        }
        // })
      } else if (!response.config.msgType) {
        if (res.msg) {
          if (response.config.url.indexOf('/bargainPurchase/createCutActivity') !== -1 || response.config.url.indexOf('/packageActivity/createPackageActivity') !== -1) {
            Message({
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: res.msg,
              duration: 5 * 1000
            })
          } else {
            Message({
              message: res.msg,
              type: 'error',
              duration: 2 * 1000
            })
          }
        }
      }
      return response
    } else {
      return response
    }
  },
  error => {
    if (error.config && error.config.url === '/ant-wpapi/wp-json/wp/v2/posts') {
      return Promise.reject(error)
    }
    console.error(error.config.url, 'errorurl') // for debug
    if (error.msg) {
      if (error.config.url.indexOf('/formService') != -1) {
        console.log('存在')
        // form服务的接口请求不抛错误
        return
      } else {
        console.log('不存在')
        Message({
          message: error.msg,
          type: 'error',
          duration: 2 * 1000
        })
      }
    } else if (error.response && error.response.data) {
      let msg = error.response.data.message
      Message({
        message: msg,
        type: 'error',
        duration: 2 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export function download(url, params, filename) {
  const downloadRes = async() => {
    const response = await fetch(url)
    const blob = await response.blob()
    const objectUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = objectUrl
    a.download = filename
    a.click()
    a.remove()
  }
  downloadRes()
}

export default service
