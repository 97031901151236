<!--
 * @Author: your name
 * @Date: 2020-08-15 15:06:15
 * @LastEditTime: 2020-08-24 10:41:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \unified-admin-front\src\views\layout\components\Sidebar\index.vue
-->
<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-row class="logo-wrapper">
      <span class="logo-name line-clamp1">AI管理</span>
    </el-row>
    <el-menu :show-timeout="200" :collapse="isCollapse" mode="vertical" router
      class="main-menu">
      <sidebar-item v-for="(route, index) in permission_routers" :key="index" :item="route" :base-path="route.path" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SidebarItem from "./SidebarItem";
import Router from "@/router";
export default {
  data() {
    return {
      permission_routers: Router.options.routes
    };
  },
  components: { SidebarItem },
  computed: {
    isCollapse() {
      // return !this.sidebar.opened;
      return false;
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.empty-avatar {
  /* background: #CCC;
  border-radius: 4px; */
}

/deep/ .el-submenu__icon-arrow {
  right: 5px;
}
</style>
