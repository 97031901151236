// set function parseTime,formatTime to filter
export { parseTime, numFilter, formatTime }
  from '@/utils'

function pluralize (time, label) {
  if (time < 1) {
    return '刚刚'
  }
  return time + label
}

// 往前补零  {{ '123' | padding(6) }} =>  '000123'
function padding (s, len) {
  var lens = len - (s + '').length
  for (let i = 0; i < lens; i++) { s = '0' + s }
  return s
}

export function timeAgo (date) {
  if (typeof (date) === 'undefined' || date == null || date == '') {
    return '----'
  }

  const time = new Date(date).getTime()
  const between = (Date.now() - Number(time)) / 1000
  if (between < 3600) {
    return pluralize(~~(between / 60), ' 分钟前')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' 小时前')
  } else {
    return pluralize(~~(between / 86400), ' 天前')
  }
}

/* 数字 格式化，千分符，小数位 */
export function numberFormatter (num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

// 垃圾，废弃
export function toThousandslsFilter (num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/*
* 参数说明：
* number：要格式化的数字
* decimals：保留几位小数, 默认：2
* dec_point：小数点符号, 默认：’。‘
* thousands_sep：千分位符号, 默认：’,‘
* roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
* */
export function amountFormat (number, decimals, dec_point, thousands_sep, roundtag) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  roundtag = roundtag || 'ceil' // "ceil","floor","round"
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 2 : Math.abs(decimals)
  const sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep
  const dec = (typeof dec_point === 'undefined') ? '.' : dec_point
  let s = ''
  const toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec)
    return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

// 金额 中文大写 格式化
export function numberParseChina (money) {
  // 汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  var cnInteger = '整'
  // 整型完以后的单位
  var cnIntLast = '圆'
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  // 金额整数部分
  var integerNum
  // 金额小数部分
  var decimalNum
  // 输出的中文金额字符串
  var chineseStr = ''
  // 分离金额后用的数组，预定义
  var parts
  if (money == '') { return '' }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

// 定义一个图片过滤器
export function DFSImg (path, w, h) {
  try {
    if (path == null || path === '') {
      return require('@/assets/img/g.gif')
    }
    if (path.indexOf('http') === 0) {
      if (path.indexOf('aliyuncs') >= 0) {
        return path;
      }
      if (path.indexOf('aliyun') >= 0) {
        path += aliyunOssImageCut(w, h)
      }
      return path
    }

    const baseImg = process.env.VUE_APP_OSS_DOMAIN
    //   let num = Math.floor(Math.random()*baseImg.length)
    //   var uri = baseImg[num]
    if (path.indexOf('/') !== 0) {
      path = '/' + path
    }

    var ext = path.substring(path.lastIndexOf('.') + 1).toLocaleLowerCase()
    if (ext == 'gif') {
      return baseImg + path
    }
    if (!Array.isArray(baseImg) && (baseImg.indexOf('aliyun') >= 0 || baseImg.indexOf('cdn') >= 0)) {
      return baseImg + path + aliyunOssImageCut(w, h)
    } else {
      // if (w && h) {
      //   path += '.' + w + 'x' + h + '.jpg'
      // }
      return baseImg + path
    }
  } catch (error) {
    console.log('DFSImg-error', error)
    return require('@/assets/img/g.gif')
  }

}

function aliyunOssImageCut (w, h) {
  let style = ''
  if (w) style += ',w_' + w
  if (h) style += ',h_' + h
  if (style.length > 0) {
    const mode = (w == 0 || h == 0) ? 'm_lfit' : 'm_pad'
    style = `?x-oss-process=image/resize,${mode},limit_0${style}`
  }
  return style
}

/**
 * 权益图标
 *
 * @export
 * @param {*} path
 * @param {*} w
 * @param {*} h
 * @returns
 */
export function DFSRightImg (path, systemCode, w, h) {
  if (path == null || path == '') {
    if (systemCode == 'FREE_SHIPPING') {
      return require('@/assets/img/right/FREE_SHIPPING.png')
    } else if (systemCode == 'DISCOUNT') {
      return require('@/assets/img/right/DISCOUNT.png')
    } else if (systemCode == 'DISTRIBUTION') {
      return require('@/assets/img/right/DISTRIBUTION.png')
    } else if (systemCode == 'OPEN_VIP_LOGO') {
      return require('@/assets/img/right/OPEN_VIP_LOGO.png')
    } else {
      return ''
    }
  }

  return DFSImg(path, w, h)
}

export function formatDate (date, fmt) {
  if (typeof (date) === 'undefined' || date == null || date == '') {
    return '----'
  }

  // 本系统返回值都是string，这段注释掉
  // if (typeof(date) === "string") {
  //     date = parseFloat(date);
  // }

  // console.log(date)
  date = new Date(date)
  // console.log(date)
  if (typeof (fmt) === 'undefined') {
    fmt = 'yyyy-MM-dd hh:mm:ss'
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'S+': date.getMilliseconds()
  }
  // console.log(o)
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padding(str, RegExp.$1.length))
    }
  }
  return fmt
}

export function NToStr (val) {
  if (val) {
    return parseFloat(parseFloat(val).toFixed(2))
  } else {
    return 0
  }
}

/**
 * 文字截取
 * @param {*} value
 * @param {*} byte
 */
export function stringCut (value, byte = 2) {
  if (!value || value === 'undefined') {
    return value
  }
  const length = byte
  return value.substr(0, length)
}

/**
 * 格式化为两位小数
 * @param {*} data
 */
export function formatTwoDecimal (data) {
  if (data) {
    return Math.round(parseFloat(data) * 100) / 100
  } else {
    return 0.00
  }
}
/**
 * 格式化为四位小数
 * @param {*} data
 */
export function formatFourDecimal (data) {
  if (data) {
    return Math.round(parseFloat(data) * 10000) / 10000
  } else {
    return 0.00
  }
}

/**
 * 计算数组的个数
 * @param {*} data
 * @returns
 */
export function ArrayResCount (data) {
  if (data.length == 0) {
    return 0
  }
  var arr = data.split(',')
  return arr.length

}

// 格式化为两位小数
export function formatNewTwoDecimal (data) {
  if (typeof data !== "undefined" && data != "" && !isNaN(data)) {
    return parseFloat(data).toFixed(2);
  } else {
    return "0.00";
  }
}
