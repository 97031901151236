import request from '@/utils/request'
import {getCurrentRoleSelectOrgId} from '@/utils/auth'
import { encryptDataWithAESToBase64 } from '@/utils/encryption'
// 登陆
export function loginByUsername(username, password) {
  const data = {
    username,
    password
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/loginByRole`,
    method: 'post',
    data
  })
  // return request({
  //   url: '/login/login',
  //   method: 'post',
  //   data
  // })
}

export function userLogin(username, password, loginType) {
  // 对密码进行加密
  // const mpassword = encryptDataWithAESToBase64(password);
  const data = {
    username,
    loginType,
    password
  }
  // if (loginType == 2) {
  //   data.password = password
  // } else {
  //   data.mpassword = mpassword
  // }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/loginByRole`,
    method: 'post',
    data
  })
}

export function changRole(roleCode) {
  let orgId = getCurrentRoleSelectOrgId() || "";
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/changRole/${roleCode}${orgId?`?orgId=${orgId}`:''}`,
    method: 'post'
  })
}

export function userLoginByMixId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/loginByMixId`,
    method: 'post',
    data
  })
}

export function getVerificationCode(phoneNumber, activityType,loginDomain) {
  const data = {
    phoneNumber,
    activityType,
    loginDomain
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/commons/getVerificationCode`,
    method: 'post',
    params: data
  })
}

export function getVerificationCodeEmail(email, activityType, name) {
  const data = {
    email,
    activityType,
    name
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/commons/getVerificationCodeEmail`,
    method: 'post',
    params: data
  })
}

export function changePasswordOne(userName, passWord) {
  const data = {
    userName,
    passWord
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/changePasswordOne`,
    method: 'post',
    data
  })
}

export function changePasswordTwo(newPassword, repeatPassword, token) {
  const data = {
    newPassword,
    repeatPassword,
    token
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/changePasswordTwo`,
    method: 'post',
    data
  })
}

export function checkVerificationCode(phoneNumber, verificationCode) {
  const data = {
    phoneNumber,
    verificationCode
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/commons/checkVerificationCode`,
    method: 'post',
    params: data
  })
}
export function mayiCheckVerificationCode(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/adminWxQrCode/checkVerificationCode`,
    method: 'post',
    params: data
  })
}

export function checkVerificationEmailCode(Email, verificationCode) {
  const data = {
    Email,
    verificationCode
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/commons/checkVerificationEmailCode`,
    method: 'post',
    params: data
  })
}

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}

export function getUserInfo(systemType) {
  let orgId;
  if(window.localStorage.getItem('isCheckRole') == 'true'){
    orgId = getCurrentRoleSelectOrgId() || "";
  } else {
    orgId = ''
  }
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/user/${systemType}${orgId?`?orgId=${orgId}`:''}`,
    method: 'get'
  })
}

export function getRegions(parentId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/systemRegion/getRegions`,
    method: 'get',
    params: { parentId }
  })
}

export function register(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/groupInfo/register`,
    method: 'post',
    data
  })
}

export function getWxQrCode() {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/adminWxQrCode/getWxQrCode`,
    method: 'get',
  })
}
export function getQyWxQrCode(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/qyWxApi/getQyWxQrCode?groupId=${id}`,
    method: 'get',
  })
}

export function getResult(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/adminWxQrCode/getResult`,
    method: 'post',
    params: data
  })
}
export function getplatformInfo(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/platformInfo/${data}`,
    method: 'get',
  })
}
export function getPlatFormInfoByCode(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/platformInfo/getPlatFormInfo`,
    method: 'post',
    data,
  })
}
// 创建密码
export function resetPassword(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/employeeInfo/resetPassword`,
    method: 'post',
    data,
  })
}
