<template>
  <div v-loading="fullscreenLoading" v-if="!item.meta.isHide" class="menu-wrapper" element-loading-text="跳转中">
    <router-link :to="resolvePath(item.path)" class="el-menu">
      <el-menu-item :index="item.path" :class="'menu-cj-0'" @click="clickHanlder(item)">
        <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fullscreenLoading: false
    }
  },
  created() {
  },
  methods: {
    resolvePath(path) {
      if (path && (path.indexOf('http://') == 0 || path.indexOf('https://') == 0 || path.indexOf('/http://') == 0 || path.indexOf('/https://') == 0)) {
        return { path: this.$route.fullPath }
      }
      return path;
    },
    clickHanlder(item) {
      if (item.name == 'text2sql') {
        window.open(item.meta.path);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-submenu__title {
  .el-icon-arrow-down:before {
    content: "\e790";
  }
}
</style>
