import request from '@/utils/request'


// 热门词查询
export function queryHotWordsPageApi(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/base/shopPopularWords/query_popular_words`,
    method: 'post',
    data
  })
}

// 热门词新增或修改
export function addEditHotWordsApi(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/base/shopPopularWords/add_popular_words`,
    method: 'post',
    data
  })
}

// 热门词删除
export function deleteHotWordsApi(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/base/shopPopularWords/delete_popular_words/` + data,
    method: 'get',
    data
  })
}

// 热门词顺序更新
export function sortHotWordsApi(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/base/shopPopularWords/update_popular_words_sort`,
    method: 'post',
    data
  })
}

export function queryShopList(query) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryForPage`,
    method: 'post',
    data: query
  })
}

export function queryForPageExcludeMultiMerchants(query) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryForPageExcludeMultiMerchants`,
    method: 'post',
    data: query
  })
}

export function deleteShop(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/delete`,
    method: 'post',
    params: { id }
  })
}

export function getShopInfoById(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/` + id,
    method: 'get'
  })
}

export function getByBoundOrgIdAndShopType(orgId, shopType) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getByBoundOrgIdAndShopType/`,
    method: 'get',
    params: { orgId, shopType }
  })
}

export function getById(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getById`,
    method: 'get',
    params: { id }
  })
}

// 根据绑定组织获取门店
export function getShopInfoByCode(code) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryStoreInformationBasedOnStoreCode/${code}`,
    method: 'get'
  })
}

// 根据绑定组织获取门店
export function getByBoundOrgId(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getByBoundOrgId/`+id,
    method: 'get',
  })
}

export function update(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/update`,
    method: 'post',
    data: data
  })
}

export function query(query) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/query`,
    method: 'post',
    data: query
  })
}

export function insert(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/insert`,
    method: 'post',
    data: data
  })
}

export function getRegions(parentId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/systemRegion/getRegions`,
    method: 'get',
    params: { parentId }
  })
}

export function list(query) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/list`,
    method: 'post',
    data: query
  })
}

// 检查门店编号是否唯一
export function checkShopCodeUnique(query) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/checkShopCodeUnique`,
    method: 'post',
    data: query
  })
}

// 根据店铺id,获得店铺配置信息
export function getShopConfiguration(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/getShopConfiguration`,
    method: 'post',
    params: { id }
  })
}

// 更改店铺配置表信息
export function updateConfiguration(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/updateConfiguration`,
    method: 'post',
    data: data
  })
}

// 根据店铺id,获得店铺用户设置
export function getShopUserSet(shopId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/getShopUserSet/${shopId}`,
    method: 'get'
  })
}

// 更改店铺用户设置
export function updateShopUserSet(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/updateShopUserSet`,
    method: 'post',
    data: data
  })
}

// 根据shopid获得店铺售后信息
export function getShopRightsInformationByShopId(shopId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopRightsInformation/getByShopId`,
    method: 'post',
    params: { shopId }
  })
}

// 更改或者新增店铺售后信息
export function saveOrUpdateShopRightsInformation(dto) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopRightsInformation/saveOrUpdate`,
    method: 'post',
    data: dto
  })
}

// 根据店铺id和支付type获得店铺支付信息
export function getShopPaymentMethodByShopIdAndPaymentType(shopId, paymentType) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPaymentMethod/getByShopIdAndPaymentType`,
    method: 'post',
    params: { shopId, paymentType }
  })
}

// 更改或者新增店铺支付信息
export function saveOrUpdateShopPaymentMethod(dto) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPaymentMethod/saveOrUpdate`,
    method: 'post',
    data: dto
  })
}

// 根据店铺id获得店铺支付信息
export function getShopPaymentMethodByShopId(shopId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPaymentMethod/getByShopId`,
    method: 'post',
    params: { shopId }
  })
}

// 更改支付配置启动状态
export function updateShopPaymentMethodActiveStatus(shopId, paymentType) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPaymentMethod/updateActiveStatus`,
    method: 'post',
    params: { shopId, paymentType }
  })
}

// 更改支付配置
export function addOrUpdateShopPaymentMethod(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPaymentMethod/addOrUpdateShopPaymentMethod`,
    method: 'post',
    data
  })
}

// 根据shopid获得店铺运费模板信息
export function getShopFreightSettings(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/query`,
    method: 'post',
    data
  })
}
// 根据orgId获得店铺运费模板信息
export function getShopFreightSettingsqueryByOrgId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/queryByOrgId`,
    method: 'post',
    data
  })
}

// 更改或者新增店铺运费模板信息
export function addOrUpdateShopFreightSettings(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/updateOrInsert`,
    method: 'post',
    data
  })
}

// 更改或者新增店铺运费明细信息
export function addOrUpdateShopFreightSettingsDetail(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettingsDetail/updateOrInsert`,
    method: 'post',
    data
  })
}

// 删除店铺运费明细信息
export function deleteShopFreightSettings(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/delete`,
    method: 'post',
    data
  })
}

// 删除店铺运费明细信息
export function deleteShopFreightSettingsDetail(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettingsDetail/delete`,
    method: 'post',
    data
  })
}

// 更改运费设置默认模板
export function updateFreightDefaultByShopId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/updateDefault`,
    method: 'post',
    data
  })
}

// 无脑根据id查询模板信息
export function getShopFreightSettingsById(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/` + id,
    method: 'get'
  })
}

// 获取销售范围信息
export function getShopSalesScopeQuery(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopSalesScope/query`,
    method: 'post',
    data
  })
}

// 新增或修改销售范围
export function updateOrInsertShopSalesScope(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopSalesScope/updateOrInsert`,
    method: 'post',
    data
  })
}

// 删除销售范围
export function deleteShopSalesScope(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopSalesScope/delete`,
    method: 'post',
    data
  })
}

// 更改销售范围默认模板
export function updateShopSalesScopeDefault(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopSalesScope/updateDefault`,
    method: 'post',
    data
  })
}

// 通过门店Id获取物流公司信息
export function getShopLogisticsCompanynByShopId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopLogisticsCompanyConfig/getShopLogisticsCompanyByShopId/` + data,
    method: 'get'
  })
}
// 通过组织Id获取物流公司信息
export function getShopLogisticsCompanyByOrgId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopLogisticsCompanyConfig/getShopLogisticsCompanyByOrgId/` + data,
    method: 'get'
  })
}

// 新增店铺物流公司配置表
export function addShopLogisticsCompanyConfig(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopLogisticsCompanyConfig/addShopLogisticsCompanyConfig`,
    method: 'post',
    data
  })
}

// 删除店铺物流公司配置表
export function deleteShopLogisticsCompanyConfig(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopLogisticsCompanyConfig/deleteShopLogisticsCompanyConfig`,
    method: 'post',
    data
  })
}

/**
 * 查询所有地址库
 */
export function listAddressLibrary(orgId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/query_list_address_library`,
    method: 'post',
    data: { orgId }
  })
}

/**
 * 查询所有地址库
 */
export function listAddressLibraryByShopId(orgId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/query_list_address_library`,
    method: 'post',
    data: { orgId }
  })
}

/**
 * 批量查询所有地址库
 */
export function listAddressLibraryByOrgIds(orgIds) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/batch_query_list_address_library`,
    method: 'post',
    data: orgIds
  })
}

/**
 * 新增或修改地址库
 * @param {*} data
 */
export function saveAddress(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/add_or_modify_address`,
    method: 'post',
    data
  })
}

/**
 * 删除地址库
 * @param {*} id
 */
export function deleteAddress(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/remove_address/${id}`,
    method: 'post'
  })
}

/**
 * 设置默认地址
 * @param {*} id
 * @param {*} type 1：收货、2：退货
 */
export function setDefaultAddress(id, type) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/addressLibraryInfo/set_default_address/${id}/${type}`,
    method: 'post'
  })
}

/**
 * 查询线下门店通过绑定商城
 */
export function getShopInfoListByAssociatedMallId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getShopInfoListByAssociatedMallId`,
    method: 'post',
    params: data
  })
}


/**
 * 查询这个员工所属集团下的启用激活的商城
 */
export function getShopInfoListByGroupId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getShopInfoListByGroupId`,
    method: 'post',
    params: data
  })
}

// 保存商家编码
export function saveMerchantCode(shopId, logisticsId, merchantCode) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopLogisticsCompanyConfig/saveMerchantCode`,
    method: 'post',
    params: { shopId, logisticsId, merchantCode }
  })
}

// 根据商城id查询多商户列表
export function queryMultiMerchantListByMallId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryMultiMerchantListByMallOrgId`,
    method: 'post',
    data
  })
}

// 开启或者关闭多商户模式
export function openOrCloseMultiMerchant(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/openOrCloseMultiMerchant`,
    method: 'post',
    data
  })
}
// 修改微商城对应的平台
export function bindShopInfoPlatformId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/bindShopInfoPlatformId`,
    method: 'post',
    data
  })
}


// 查询商户的信息及入驻商城和绑定的商城
export function queryStoreRelMall(orgId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryStoreRelMall`,
    method: 'get',
    params: { orgId }
  })
}

// 查询商户绑定的商城
export function queryStoreRelMallByStoreOrgId(storeOrgId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryStoreRelMallByStoreOrgId`,
    method: 'get',
    params: { storeOrgId }
  })
}

// 新增商户
export function saveMerchant(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/saveMerchant`,
    method: 'post',
    data
  })
}

// 新增商户
export function updateMerchant(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateMerchant`,
    method: 'post',
    data
  })
}

// 新增商户
export function importMerchant(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/importMerchant`,
    method: 'post',
    data
  })
}

// 修改商户状态、业务员等
export function updateMerchantStateBatch(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateMerchantStateBatch`,
    method: 'post',
    data
  })
}

// 通过id查询商户详细信息
export function queryMultiMerchantListById(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/queryMultiMerchantListById`,
    method: 'get',
    params: { id }
  })
}

// 查询当前多商户已经存在的所有orgId
export function queryStoreOrgIds() {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/mallRelevanceStore/queryStoreOrgIds`,
    method: 'get'
  })
}

// 获取商城微信公众号
export function getShopWxPublic() {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopWxInfo/get_shop_wx_public`,
    method: 'post'
  })
}
// 根据ShopId 查询详情
export function shopPurchaseDetail(shopId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPurchaseInstructions/` + shopId,
    method: 'get'
  })
}

// 根据shopid获得店铺运费模板信息
export function getShopFreightSettingsGroupId(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopFreightSettings/queryByGroupId`,
    method: 'post',
    data
  })
}

// 子商户获取商户信息
export function getShopInfoByBoundOrg(orgId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getByBoundOrgIdNew/${orgId}`,
    method: 'get'
  })
}

// 子商户获取微信二维码
export function getsubShopWxQrcode(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/commons/queryWXQrcode`,
    method: 'post',
    data
  })
}

// 分享场景参数记录表
export function getsubShopShareRecord(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shareSceneRecord/saveShareSceneRecord`,
    method: 'post',
    data
  })
}

// 子商户根据平台id 查询商户

export function getShopInfoListById(id) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/getByMallIdList/${id}`,
    method: 'get'
  })
}


// 子商户首页销售额度报表
export function salesQuota(data) {
  return request({
    url: `${process.env.VUE_APP_BI_URL}/order/salesQuota`,
    method: 'post',
    data
  })
}

// 子商户首页销售数量排名报表
export function salesQuantityRanking(data) {
  return request({
    url: `${process.env.VUE_APP_BI_URL}order/salesQuantityRanking`,
    method: 'post',
    data
  })
}


// 获取进店规则
export function searchEnterShopRuleList(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/enterShopRule/searchEnterShopRuleList?enterShopType=${data}`,
    method: 'get',
  })
}

// 展示门店分页列表
export function searchShowShop(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/showShop/searchShowShop`,
    method: 'post',
    data
  })
}


// 删除展示门店
export function deleteStore(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/showShop/delete/${data}`,
    method: 'delete',
  })
}

// 查询进店记录
export function searchEnterShopRecordList(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/enterShopRecord/searchEnterShopRecordList`,
    method: 'post',
    data
  })
}

// 修改分销员配置
export function updateSalesmanEnterShop(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateSalesmanEnterShop`,
    method: 'post',
    data
  })
}

// 新增或修改进店规则
export function addOrUpdateEnterShopRule(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/enterShopRule/addOrUpdateEnterShopRule`,
    method: 'post',
    data
  })
}

// 保存展示门店
export function saveShowShop(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/showShop/saveShowShop`,
    method: 'post',
    data
  })
}


// 通过地址获取坐标
export function queryCoorByAdd(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/tencentMap/getCoordinateByAddress`,
    method: 'post',
    data
  })
}

// 设置店铺联系人

export function updateShopContact(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateShopManager`,
    method: 'post',
    data
  })
}

// 修改门店自提信息
export function saveOrUpdateShopInfoSelfPickup(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/saveOrUpdateShopInfoSelfPickup`,
    method: 'post',
    data
  })
}
// 设置分销总开关
export function updateShopDistributionMasterSwitch(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateShopDistributionMasterSwitch`,
    method: 'post',
    data
  })
}

// 门店禁用启用

export function updateShopDisable(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopInfo/updateShopDisable`,
    method: 'post',
    data
  })
}

// 获取协议信息
export function getShopAgreementInfo(shopId) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopAgreement/shopAgreementInfo/${shopId}`,
    method: 'POST'
  })
}

// 新增或修改协议信息
export function saveOrUpdateShopAgreementInfo(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopAgreement/saveOrUpdateShopAgreementInfo`,
    method: 'POST',
    data
  })
}

// 新增或修改同城配送
export function saveOrUpdateShopInfoSameCityDelivery(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopConfiguration/saveOrUpdateShopInfoSameCityDelivery`,
    method: "POST",
    data
  })
}

// 添加或更新门店自提配置
export function addOrUpdate(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPickupSettings/addOrUpdate`,
    method: "POST",
    data
  })
}

// 查询门店自提配置列表
export function getShopPickupSettingsList(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPickupSettings/getShopPickupSettingsList`,
    method: "POST",
    data
  })
}

// 删除门店自提配置
export function deleteShopPickupSettings(params) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/shopPickupSettings/deleteShopPickupSettings`,
    method: "POST",
    params
  })
}
