import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const CurrentRoleCodeKey = 'Current-Role-Code'
const CurrentRoleSelectOrgId = 'Current-Role-Select-OrgId'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCurrentRoleCode() {
  return Cookies.get(CurrentRoleCodeKey)
}

export function setCurrentRoleCode(currentRole) {
  return Cookies.set(CurrentRoleCodeKey, currentRole)
}
export function removeCurrentRoleCode() {
  return Cookies.remove(CurrentRoleCodeKey)
}
export function getCurrentRoleSelectOrgId() {
  return Cookies.get(CurrentRoleSelectOrgId)
}

export function setCurrentRoleSelectOrgId(currentRole) {
  return Cookies.set(CurrentRoleSelectOrgId, currentRole)
}

export function removeCurrentRoleSelectOrgId() {
  return Cookies.remove(CurrentRoleSelectOrgId)
}

export function getCookie(key) {
  return Cookies.get(key)
}

export function setCookie(key, value, expiresDay) {
  if (!expiresDay || typeof expiresDay !== 'number' || expiresDay === 0) {
    return Cookies.set(key, value)
  }
  return Cookies.set(key, value, { expires: expiresDay })
}

export function removeCookie(key) {
  return Cookies.remove(key)
}
