import Vue from "vue";
import App from "./App.vue";
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from "element-ui";
import mytest from "mayi-business-component-library";
import "mayi-business-component-library/dist/main.css";
import "element-ui/lib/theme-chalk/index.css";
import './utils/element-ui'
import '@/styles/index.scss' // global css
import router from "./router";
import store from './store'
import constant from "./utils/constant";
import * as filters from "./filters"; // global filters
import "./utils/qiankun";
import loadMore from "@/utils/select.js";
import { getAllSystemCode } from '@/api/system/sysCode'
import '../static/cms/css/commonStyle.css'
import './permission' // permission control

window.document.documentElement.setAttribute('data-theme', 'dark')

//  全局引入vue中央事件总线
import eventBus from "./utils/eventBus";
Vue.prototype.$eventBus = eventBus;

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 获取某个类型的系统码表
Vue.prototype.getSysCode = function (type) {
  const data = Vue.prototype.SYS_CODE
  const typeArr = data[type]
  if (!typeArr) {
    return []
  } else {
    return typeArr
  }
}

Vue.config.productionTip = false;
Vue.use(ElementUI);
// 常量
Vue.prototype.CONSTANT = constant;
Vue.use(mytest, { tokenKey: "Admin-Token" });

Vue.use(loadMore);
const permissionsEunm = {
  development: [
    "attractInvestmentBatchImport",
    "attractInvestmentExport",
    "attractInvestmentPreliminaryExamination",
    "attractInvestmentFinalJudgment",
    "attractInvestmentListExport",
    "attractInvestmentListPreliminaryExamination",
    "attractInvestmentListFinalJudgment",
    "attractInvestmentDetail",
    "attractInvestmentDetailSave",
    "attractInvestmentDetailExport",
    "attractInvestmentDetailCancel",
    "merchantManagementDetail",
    "merchantManagementDisabled",
    "merchantManagementEnable",
    "mealExpensesGenerateSettlementDoc",
    "mealExpensesGenerateWriteOffDoc",
    "mealExpensesDetail",
    "mealExpensesListMarkReceipt",
    "mealExpensesListExport",
    "mealExpensesDetailExport",
    "preOrderDetail",
    "settlementDocExportDetail",
    "settlementDocMarkPaid",
    "settlementDocListExport",
    "settlementDocListExportDetail",
    "settlementDocDetail",
    "settlementDocListCancel",
    "settlementDocListMarkPaid",
    "settlementDocDetailExport",
    "settlementDocDetailExportDetail",
    "settlementDocDetailDetailPrinting",
    "writeOffDocExportDetail",
    "writeOffDocMarkWriteOff",
    "writeOffDocMarkSubmitted",
    "writeOffDoclistExport",
    "writeOffDoclistExportDetail",
    "writeOffDocDetail",
    "writeOffDoclistCancel",
    "WriteOffDocListMarkCancelled",
    "WriteOffDoclistMarkWriteOff",
    "writeOffDocDetailExport",
    "writeOffDocDetailExportDetail",
    "mealExpensesMealCostToExel",
    "mealExpensesProcess",
    "allAdvanceOrderPreOrderDetail",
    "settlementDocDetailModifyVoucher",
    "merchantManagementChange",
    "allAdvanceOrderListToExel",
  ],
};
// 按钮权限js方法 .打包的时候 去掉
// let permissions =
let permissions = permissionsEunm[process.env.NODE_ENV] || [];

Vue.prototype.hasPermission = function (key) {
  let hasPermission = false;
  if (key) {
    let keys = [];
    let i = 0;
    keys = key.split(","); // 支持多个
    for (const k of permissions) {
      for (let j = 0; j < keys.length; j++) {
        if (keys[j] == k) {
          i++;
        }
      }
      if (i == keys.length) {
        hasPermission = true;
        break;
      }
    }
  }
  return hasPermission;
};

let instance = null;
async function render(parent = {}) {
  let { container, data } = parent;
  console.log("micro-demo--", data);
  if (data) {
    permissions = data.store.getters.permissions;
    console.log(permissions, "-permissions");
  }
  const res = await getAllSystemCode()
  Vue.prototype.SYS_CODE = res.data.data
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
    data() {
      return {};
    },
  }).$mount(
    container ? container.querySelector("#micro-ai") : "#micro-ai"
  );
}

function storeTest(props) {
  props.onGlobalStateChange &&
    props.onGlobalStateChange(
      (value, prev) =>
        console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
      true
    );
  props.setGlobalState &&
    props.setGlobalState({
      ignore: props.name,
      user: {
        name: props.name,
      },
    });
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  console.log("[vue] vue app bootstraped");
}
/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props) {
  console.log("[vue] props from main framework", props);
  storeTest(props);
  render(props);
}
/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
  // ReactDOM.unmountComponentAtNode(document.getElementById('react15Root'));
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}
/**
 * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
 */
export async function update(props) {
  console.log("update props", props);
}
console.log('window.__POWERED_BY_QIANKUN__->', window.__POWERED_BY_QIANKUN__);
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
