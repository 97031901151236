import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=12a5a9c0&scoped=true&xmlns%3Ael-icon-close=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a5a9c0",
  null
  
)

export default component.exports