module.exports = {
  PAGE_SIZES: [10, 20, 30, 50, 100],
  INPUT_LENGTH_RES_MAX: 100,
  INPUT_LENGTH_EX_MAX: 100,
  HEADER_TOKEN_KEY: 'Mayi-Token',
  SUPER_GUIDE_CODE: 'SUPER_GUIDE',
  KUKA_BPM: 'kuka_bpm',
  KUKA_CBS: 'KUKA_CBS',
  KUKA_ALI_MQ: 'KUKA_ALI_MQ',
  MONITOR: 'MONITOR',
  DEALER_BOSS: 'DEALER_BOSS',
  SHOP_MANAGER: 'SHOP_MANAGER',
  SALES: 'SALES',
  UPLOAD_URL: process.env.VUE_APP_BASE_API + '/baseService/upload/uploadFile.htm',
  WX_DEFAULT_COLORS: {
    Color010: '#63b359',
    Color020: '#2c9f67',
    Color030: '#509fc9',
    Color040: '#5885cf',
    Color050: '#9062c0',
    Color060: '#d09a45',
    Color070: '#e4b138',
    Color080: '#ee903c',
    Color081: '#f08500',
    Color082: '#a9d92d',
    Color090: '#dd6549',
    Color100: '#cc463d',
    Color101: '#cf3e36',
    Color102: '#5E6671'
  },
  DASHBOARD_ROUTE_NAME: 'Dashboard'
}
