/*
 * @Author: your name
 * @Date: 2021-05-10 11:59:41
 * @LastEditTime: 2021-05-20 14:48:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \unified-admin-front\src\store\getters.js
 */
const getters = {
  platformInfo: state => state.app.platformInfo,
  queryByDomainFlag: state => state.app.queryByDomainFlag,
  groupInfo: state => state.app.groupInfo,
  customLoginUrl: state => state.app.customLoginUrl,
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  sideBarStyle: state => state.app.theme.sideBarStyle,
  mainColor: state => state.app.theme.mainColor,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  errorLogs: state => state.errorLog.logs,
  userImageUrl: state => state.user.userImageUrl,
  permissions: state => state.permission.permissions,
  notReadNoticeCount: state => state.user.notReadNoticeCount,
  currentRole: state => state.user.currentRole,
  roleList: state => state.user.roleList,
  employeeId: state => state.user.employeeId,
  shopId: state => state.listToEntity.shopId,
  shopType: state => state.listToEntity.shopType,
  groupId: state => state.user.groupId,
  subMerchantRoleInfo: state => state.user.subMerchantRoleInfo,
  isLsHeadquarters: state => state.user.isLsHeadquarters,
  specShopInfo: state => state.listToEntity.specShopInfo // 存储Saas环境下，连锁商城后台当前店铺信息
}
export default getters
