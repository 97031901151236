import { debounce } from "@/utils/toolKit.js";
export default {}.install = (Vue, options = {}) => {
  Vue.directive("loadmore", {
    inserted(el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTDOWN_DOM = el.querySelector(
        ".el-select-dropdown .el-select-dropdown__wrap"
      );
      SELECTDOWN_DOM.addEventListener("scroll", function () {
        //是否触底
        const CONDITION =
          this.scrollHeight - this.scrollTop <= this.clientHeight + 1;
        const res = debounce(binding.value, 100);
        if (CONDITION) {
          res();
        }
      });
    },
  });
};
