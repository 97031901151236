import routerInstance from './router'
import store from './store'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken, setToken
} from '@/utils/auth' // getToken from cookie

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

// 无需登录即可访问的URL白名单,正则表达式
const whiteList = [
  '/protal',
  '/login',
  '/loginMain',
  '/forgetpwd',
  '/register',
  '/readArticle',
  '/readAttchment',
  '/authredirect',
  '/opendoc',
  '/groupLogin/.*',
  '/wechatLodding',
  '/outAuthredirect',
  '/readArticle',
  '/announce',
  '/announce/.*',
  '/zshAnnounce',
  '/zshAnnounce/.*',
  '/metabase/login',
  '/sso/login',
  '/download/pos',
  '/wxminiproglog',
  '/jd_oauth_cb'
]

function pathHandler(arr, pushArr) {
  arr.forEach(item => {
    // if(item.path.substring(item.path.indexOf(':') + 1,item.path.length)){
    const i = item.path.indexOf(':')
    let str = ''
    if (i > -1) {
      str = item.path.substring(0, i - 1)
    } else {
      str = item.path
    }

    if (!str.startsWith('/')) {
      str = '/' + str
    }
    pushArr.push(str)
    // }
    // pushArr.push(item.path)

    if (item.children) {
      pathHandler(item.children, pushArr)
    }
  })
}

function isWhite(path) {
  for (let index = 0; index < whiteList.length; index++) {
    const whiteUrl = whiteList[index]
    const reg = new RegExp(whiteUrl, 'g')
    if (reg.test(path)) {
      return true
    }
  }
  return false
}

addRouterHooks(routerInstance)
// 微应用主动添加 拦截
export function addRouterHooks(router, micro) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start() // start progress bar
    if (to.query && getToken() && to.query.token) {
      if (to.query.token == getToken()) {
        next()
      } else {
        setToken('')
      }
    }

    if (getToken()) { // determine if there has token
      /* has token*/
      if (isWhite(to.path) && !(to.path === '/login' || to.path.indexOf('/groupLogin/') != -1)) {
        next()
        return
      }
      if (to.path === '/login' || to.path.indexOf('/groupLogin/') != -1) {
        next({
          path: '/'
        })
        NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
      } else {
        console.log(store.getters.name, '!store.getters.name', micro)
        next()
      }
    } else {
      // console.log('d', )
      if (isWhite(to.path)) { // 在免登录白名单，直接进入
        next()
      } else {
        console.log(to, from)
        if (localStorage.getItem('toHome') == '1') {
          next(`/login`)
        } else {
          next(`/login?pages=${to.path}`) // 否则全部重定向到登录页
        }

        NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })

  router.afterEach((to, from) => {
    NProgress.done() // finish progress bar
  })
}
