import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let prefix = window.__POWERED_BY_QIANKUN__ ? '/micro-ai' : ''

export const constantRoutes = [
  {
    path: `${prefix}/`,
    name: 'knowledgeBase-appList',
    component: () => import('../views/knowledgeBase/appList'),
    meta: { title: 'AI应用' }
  },
  {
    path: `${prefix}/knowledgeBase/appDetail`,
    name: 'knowledgeBase-appDetail',
    component: () => import('../views/knowledgeBase/appDetail'),
    meta: { title: 'AI应用详情', isHide: true, }
  },
  {
    path: `${prefix}/knowledgeBase/list`,
    name: 'knowledgeBase-list',
    component: () => import('../views/knowledgeBase/list'),
    meta: { title: 'AI知识库' }
  },
  {
    path: `${prefix}/knowledgeBase/detail`,
    name: 'knowledgeBase-detail',
    component: () => import('../views/knowledgeBase/detail'),
    meta: { title: 'AI知识库详情', isHide: true, }
  },
  {
    path: `${prefix}/knowledgeBase/tagList`,
    name: 'knowledgeBase-tagList',
    component: () => import('../views/knowledgeBase/tagList'),
    meta: { title: 'AI知识点标签管理' }
  },
  {
    path: `${prefix}/knowledgeBase/classifyList`,
    name: 'knowledgeBase-classifyList',
    component: () => import('../views/knowledgeBase/classifyList'),
    meta: { title: 'AI知识点分类管理' }
  },
  {
    path: `${prefix}/knowledgeBase/AiTools`,
    name: 'knowledgeBase-AiTools',
    component: () => import('../views/knowledgeBase/AiTools'),
    meta: { title: 'AI工具' }
  },
  {
    path: `${prefix}/login`,
    name: 'MayiLogin',
    component: () => import('../views/login/MayiLogin'),
    meta: { title: '登录', isHide: true }
  },
  {
    path: ``,
    name: 'text2sql',
    meta: { title: 'text2sql', path: 'https://text2sql.mayi888.cn/#training-data' } // https://text2sql.mayi888.cn/#training-data || http://10.241.58.5:18888
  },
]

const vueRouter = new VueRouter({
  routes: constantRoutes
})

if (window.__POWERED_BY_QIANKUN__) {
  vueRouter.beforeEach((to, from, next) => {
    console.log(prefix, '=====', to.path, '---+++')
    if (!to.path.startsWith('/micro-ai')) next(`${prefix + to.path}`)
    else next()
  })

}

export default vueRouter
