import request from '@/utils/request'

export function getAllSystemCode() {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/sysCode/getAllSysCode`,
    method: 'get'
  })
}

export function fetchSystemCodeList(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/sysCode/queryList`,
    method: 'post',
    data
  })
}

export function updateRidesSystemCode() {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/sysCode/update_redis`,
    method: 'post'
  })
}

export function addSysCode(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/sysCode/addOrUpdate`,
    method: 'post',
    data
  })
}

// 根据type查询码表列表
export function getListByCode(data) {
  return request({
    url: `${process.env.VUE_APP_BASESER_API}/sysCode/queryListByCode/${data}`,
    method: 'post',
    data
  })
}
